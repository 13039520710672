import React, { useState, useEffect } from 'react';
import { getMissions, cancel } from '../../services/missions';

const Missions = () => {
    const [missions, setMissions] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const total = completed.length + missions.length;
        if (total !== 0 && completed.length !== 0) {
            setProgress((completed.length / total) * 100);
        }
    }, [completed, missions])

    useEffect(() => {
        req();

        return () => {
            cancel();
        }
    }, []);

    const req = async () => {
        const data = await getMissions();
        setMissions(data.missions);
        setCompleted(data.completed);
    }

    return (
        <div className="col-12 col-xl-8 col-xxl-9 mb-3">
            {
                missions.length > 0 && (
                    <div className="col-12 mb-3">
                        <div className="border-bottom border-3 border-gray-200 pb-1 mb-4 mb-xl-5 mt-3 mt-xl-4">
                            <h3 className="font-weight-semibold pb-xl-4">Sumá más puntos</h3>
                        </div>
                        <div className="row">
                            {
                                missions.map(mission => (
                                    <div className="col-12 col-xxl-6 col-xl-12 col-lg-6 col-md-6" key={mission._id}>
                                        <div className="card mb-4 border border-secondary justify-content-around">
                                            <div className="d-flex flex-row">
                                                <div className="bg-secondary text-white d-flex flex-column align-items-center justify-content-center p-1 mission-square">
                                                    <span className="res-font-size fw-normal text-uppercase">Sumá</span>
                                                    <span className="fs-2 fw-normal numberLineHeight">{mission.points}</span>
                                                    <span className="res-font-size fw-normal text-uppercase">Puntos</span>
                                                </div>
                                                {mission.image && (<div className='align-self-center mission-square'>
                                                    <img src={mission.image} alt="mission icon" className='p-3'/>
                                                </div>)}
                                                <div className="align-self-center" style={{ flex: mission.image ? 3 : 4 }}>
                                                    <span className="caption h6 d-block text-center">{mission.title}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                completed.length > 0 && (
                    <div className="col-12 mb-3">
                        <div className="border-bottom border-3 border-gray-200 pb-1 mb-4 mb-xl-5 mt-3 mt-xl-4">
                            <h3 className="font-weight-semibold pb-xl-4">Misiones completadas</h3>
                        </div>
                        <div className="progress mb-4 mb-xl-5">
                            <div className="progress-bar text-white fw-bold" role="progressbar" style={{ width: `${progress}%` }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                        </div>
                        <div className="row">
                            {
                                completed.map(mission => (
                                    <div className="col-12 col-xxl-6 col-xl-12 col-lg-6 col-md-6" key={mission._id}>
                                        <div className="card mb-4 border border-secondary justify-content-around" >
                                            <div className="d-flex flex-row">
                                                <div className="bg-secondary text-white d-flex flex-column align-items-center justify-content-center p-1 mission-square" >
                                                    <span className="res-font-size fw-normal text-uppercase">Sumaste</span>
                                                    <span className="fs-2 fw-normal numberLineHeight">{mission.points}</span>
                                                    <span className="res-font-size fw-normal text-uppercase">Puntos</span>
                                                </div>
                                                {mission.mission.image && (<div className='align-self-center mission-square'>
                                                    <img src={mission.mission.image} alt="mission icon" className='p-3' />
                                                </div>)}
                                                <div className="align-self-center" style={{ flex: mission.mission.image ? 3 : 4 }}>
                                                    <p className="caption h6 d-block text-center">{mission.mission.title}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default Missions;
